<script lang="ts" setup>
import AppStore from '~/components/reusables/AppStore.vue'
import GooglePlay from '~/components/reusables/GooglePlay.vue'

definePageMeta({
  layout: 'menu',
})

const {
  public: { siteUrl, appLink },
} = useRuntimeConfig()

const breakpoints = useBreakpoints({
  laptop: 1024,
  desktop: 1280,
})

const { t } = useI18n()
const localeRoute = useLocaleRoute()

useSeoMeta({
  title: t('real-world-on-chain'),
  description: t('tokenized-and-ai-powered-events'),
  ogTitle: t('real-world-on-chain'),
  ogDescription: t('tokenized-and-ai-powered-events'),
  ogImage: `${siteUrl}/images/head/snippet_screen.png`,
  ogImageWidth: '1000',
  ogImageHeight: '300',
  ogImageType: 'image/png',
  ogType: 'website',
  twitterTitle: t('real-world-on-chain'),
  twitterDescription: t('tokenized-and-ai-powered-events'),
  twitterImage: `${siteUrl}/images/head/snippet_screen.png`,
  twitterCard: 'summary_large_image',
})

const smallerThanXl = breakpoints.smaller('desktop')
const largerThanXl = breakpoints.greater('desktop')

const partnerClasses: { [key: number]: string } = {
  1: 'min-w-48.5 max-w-48.5',
  2: 'min-w-39.5 max-w-39.5',
  3: 'min-w-44 max-w-44',
  4: 'min-w-36.5 max-w-36.5',
  5: 'min-w-51.5 max-w-51.5',
  6: 'min-w-12.5 max-w-12.5',
  7: 'min-w-14 max-w-14',
  8: 'min-w-32.5 max-w-32.5',
}

const partners = [
  {
    id: 1,
    img: 'i-belong:google_cloud w-48.5 ',
  },
  {
    id: 2,
    img: 'i-belong:open_ai w-39.5',
  },
  {
    id: 3,
    img: 'i-belong:digital_ocean w-44',
  },
  {
    id: 4,
    img: 'i-belong:segment w-36.5',
  },
  {
    id: 5,
    img: 'i-belong:tencent_cloud w-51.5',
  },
  {
    id: 6,
    img: 'i-belong:aws w-12.5',
  },
  {
    id: 7,
    img: 'i-belong:sui_logo w-14',
  },
  {
    id: 8,
    img: 'i-belong:starknet_logo w-32.5',
  },
]

const features = ref([
  {
    id: 1,
    title: t('tokenize-any-real-world-location'),
    img: breakpoints.desktop.value
      ? '/images/home/tokenized_location_laptop.png'
      : '/images/home/tokenized_location.png',
  },
  {
    id: 2,
    title: t('distribute-on-chain-bounties-for-offline-activity'),
    img: '/images/home/onchain_bounties.png',
  },
  {
    id: 3,
    title: t('generate-digital-keys-and-nft-tickets'),
    img: '/images/home/nft_tickets.png',
  },
  {
    id: 4,
    title: t('innovate-raffles-and-community-management-with-ai'),
    img: '/images/home/ai_raffles.png',
  },
  {
    id: 5,
    title: t('enable-location-based-rewards'),
    img: '/images/home/location_rewards.png',
  },
])

const useCases = [
  {
    id: 1,
    title: t('bounty-marketplace-for-real-world-activities'),
    image: 'i-belong:bounty_marketplace',
  },
  {
    id: 2,
    title: t('nft-sbt-tickets-for-events'),
    image: 'i-belong:nft_sbt_tickets',
  },
  {
    id: 3,
    title: t('ai-raffles-and-community-engagement'),
    image: 'i-belong:ai_raffles',
  },
  {
    id: 4,
    title: t('smart-space-access-with-depin'),
    image: 'i-belong:smart_access_depin',
  },
  { id: 5, title: t('content-gating'), image: 'i-belong:content_gating' },
  {
    id: 6,
    title: t('physical-goods-verification'),
    image: 'i-belong:physical_verification',
  },
]

const INN_UTIL = [
  {
    year: 2025,
    title: t('innovation-and-utility'),
    quarters: {
      Q1: [
        {
          id: 1,
          text: t('worldwide-airdrop-with-offline-claiming'),
          checked: true,
        },
        { id: 2, text: t('ai-raffles-and-ai-agents'), checked: true },
        { id: 3, text: t('integration-with-analog'), checked: true },
        { id: 4, text: t('ton-launch'), checked: false },
        { id: 5, text: 'TGE', checked: false },
        { id: 6, text: t('sui-launch'), checked: true },
        { id: 7, text: t('location-based-bounty-marketplace'), checked: false },
      ],
      Q2: [
        { id: 8, text: t('depin-rentals'), checked: false },
        { id: 9, text: t('scientific-research-tokenization'), checked: false },
        { id: 10, text: t('gamification'), checked: false },
        { id: 11, text: t('expo-osaka-activation'), checked: false },
      ],
    },
  },
  {
    year: 2024,
    title: t('ai-multi-chain-growth'),
    quarters: {
      Q4: [
        { id: 12, text: t('advanced-whitelabel'), checked: true },
        { id: 13, text: t('devcon-activation'), checked: true },
        { id: 14, text: t('geo-based-minting'), checked: true },
        { id: 15, text: t('second-audit-by-hacken'), checked: true },
      ],
      Q3: [
        { id: 16, text: t('ai-driven-templates'), checked: true },
        { id: 17, text: t('dynamic-pricing'), checked: true },
        { id: 18, text: t('api-for-gaming'), checked: true },
      ],
      Q2: [
        { id: 19, text: t('multi-chain-x-fiat-payments'), checked: true },
        { id: 20, text: t('sdk-launch'), checked: true },
        {
          id: 21,
          text: t('ai-generated-user-and-brand-landing-pages'),
          checked: true,
        },
      ],
      Q1: [
        {
          id: 22,
          text: t('worlwide-airdrop-with-offline-claiming'),
          checked: true,
        },
        { id: 23, text: t('ai-raffles-and-ai-agents-0'), checked: true },
        { id: 24, text: t('integration-with-analog-0'), checked: true },
      ],
    },
  },
  {
    year: 2023,
    title: t('scaling-and-security'),
    quarters: {
      Q3Q4: [
        { id: 25, text: t('ai-moderation'), checked: true },
        { id: 26, text: t('security-scaling'), checked: true },
        { id: 27, text: t('access-tokenization-at-scale'), checked: true },
      ],
      Q1Q2: [
        {
          id: 28,
          text: t(
            'chains-expansion-eth-polygon-base-starknet-skale-and-celo-networks'
          ),
          checked: true,
        },
        { id: 29, text: t('transaction-upgrades'), checked: true },
      ],
    },
  },
  {
    year: 2022,
    title: t('web3-expansion'),
    quarters: {
      0: [
        { id: 30, text: t('nft-collections'), checked: true },
        { id: 31, text: t('metamask-support'), checked: true },
        { id: 32, text: t('profile-enhancements'), checked: true },
        { id: 33, text: t('discovery-tools'), checked: true },
      ],
    },
  },
  {
    year: 2021,
    title: 'FOUNDATION',
    quarters: {
      0: [
        { id: 34, text: t('infra-setup'), checked: true },
        { id: 35, text: t('multi-platform-rollout'), checked: true },
        { id: 36, text: t('social-graph-launch'), checked: true },
        { id: 37, text: t('event-tools-integration'), checked: true },
      ],
    },
  },
]

const caseStudy = [
  {
    title: t('lampu-festival-2024'),
    img: '/images/home/lampu_festival.png',
    features: [
      { text: t('flexible-payment-system'), highlight: t('fiat-and-crypto') },
      { text: t('multiple-ticket-price-tiers') },
      { text: t('branding-landing-pages') },
      { text: t('attendee-notifications') },
    ],
    location: t('bali'),
    link: '/lampu-2024',
  },
  {
    title: t('devcon-week-2024'),
    img: '/images/home/devcon_2024.png',
    features: [
      { text: t('collaborations-with-tier-1-crypto-companies') },
      { text: t('30-phygital-stations-with-nfc-chips') },
      { text: t('branded-nfts-with-rewards') },
      { text: t('article-in-cointelegraph') },
    ],
    location: t('bangkok'),
    link: '/devcon-week-2024',
  },
  {
    title: t('chain-atlas-2025'),
    img: '/images/home/chain_atlas_2025.png',
    features: [
      { text: t('gas-free-multi-chain-geo-based-crypto-game') },
      { text: t('1-000-000-prize-pool') },
      { text: t('available-in-150-cities-worldwide') },
      { text: t('major-l1-and-l2-partnerships') },
      { text: t('atlas-ai-agent') },
    ],
    location: t('worldwide'),
    link: '/chainatlas',
  },
]

const product = [
  {
    id: 1,
    title: t('live-application-in-app-store-and-google-play'),
    image: 'i-belong:apple_google',
  },
  {
    id: 2,
    title: t(
      'zero-friction-access-nft-sbt-purchase-in-30-seconds-without-a-crypto-wallet'
    ),
    image: 'i-belong:zero_friction',
  },
  {
    id: 3,
    title: t('gasless-transactions'),
    image: 'i-belong:gasless_transactions',
  },
  {
    id: 4,
    title: t(
      'multichain-currently-operational-on-eth-pol-skale-base-blast-6-more-to-come'
    ),
    image: 'i-belong:multichain',
  },
  {
    id: 5,
    title: t('apple-pay-and-google-pay-integration'),
    image: 'i-belong:app_apple_google',
  },
]
const product1 = [
  {
    id: 1,
    title: t(
      'ai-agents-for-higher-clv-through-community-engagement-content-and-product-discovery'
    ),
    image: 'i-belong:ai-engagement',
  },
  {
    id: 2,
    title: t(
      'robust-api-and-sdk-powering-the-next-wave-of-gated-applications-built-on-long-protocol'
    ),
    image: 'i-belong:api-sdk',
  },
  {
    id: 3,
    title: t('location-based-tokens-and-task-bounties-ai-powered-engagement'),
    image: 'i-belong:location-tokens',
  },
  {
    id: 4,
    title: t('full-whitelabel-capability'),
    image: 'i-belong:whitelabel',
  },
]

const animation = { duration: 20000, easing: (t) => t }

const [container] = useKeenSlider({
  loop: true,
  drag: false,
  mode: 'free-snap',
  created(s) {
    s.moveToIdx(5, true, animation)
  },
  updated(s) {
    s.moveToIdx(s.track.details.abs + 5, true, animation)
  },
  animationEnded(s) {
    s.moveToIdx(s.track.details.abs + 5, true, animation)
  },

  slides: {
    perView: 'auto',
    spacing: 65,
    origin: 'center',
  },
})

const [collabsFirstHalf] = useKeenSlider({
  loop: true,
  drag: false,
  mode: 'free-snap',
  created(s) {
    s.moveToIdx(5, true, animation)
  },
  updated(s) {
    s.moveToIdx(s.track.details.abs + 5, true, animation)
  },
  animationEnded(s) {
    s.moveToIdx(s.track.details.abs + 5, true, animation)
  },

  slides: {
    perView: 'auto',
    spacing: 6,
    origin: 'center',
  },
})

const [collabsSecondHalf] = useKeenSlider({
  loop: true,
  drag: false,
  mode: 'free-snap',
  rtl: true,
  created(s) {
    s.moveToIdx(5, true, animation)
  },
  updated(s) {
    s.moveToIdx(s.track.details.abs + 5, true, animation)
  },
  animationEnded(s) {
    s.moveToIdx(s.track.details.abs + 5, true, animation)
  },

  slides: {
    perView: 'auto',
    spacing: 6,
    origin: 'center',
  },
})
const collabsAndCommunity = [
  { id: 1, name: 'Alphabot', image: '/images/home/collabs/alphabot.png' },
  { id: 2, name: 'Analog', image: '/images/home/collabs/analog.png' },
  { id: 3, name: 'Anya frens', image: '/images/home/collabs/anya_frens.png' },
  { id: 4, name: 'Apelist', image: '/images/home/collabs/apelist.png' },
  { id: 5, name: 'Ash hub', image: '/images/home/collabs/ash_hub.png' },
  { id: 6, name: 'Blvck paris', image: '/images/home/collabs/blvck_paris.png' },
  { id: 7, name: 'Circle', image: '/images/home/collabs/circle.png' },
  {
    id: 8,
    name: 'Connectivity',
    image: '/images/home/collabs/connectivity.png',
  },
  { id: 9, name: 'Clokies', image: '/images/home/collabs/clokies.png' },
  {
    id: 10,
    name: 'Coin telegraph',
    image: '/images/home/collabs/cointelegraph.png',
  },
  {
    id: 11,
    name: 'Cricket fly',
    image: '/images/home/collabs/cricket_fly.png',
  },
  { id: 12, name: 'Degen dao', image: '/images/home/collabs/degen_dao.png' },
  { id: 13, name: 'Dislands', image: '/images/home/collabs/dislands.png' },
  { id: 14, name: 'Embassy', image: '/images/home/collabs/embassy.png' },
  { id: 15, name: 'Gate', image: '/images/home/collabs/gate.png' },
  {
    id: 16,
    name: 'Generative dungeon',
    image: '/images/home/collabs/generative_dungeon.png',
  },
  { id: 17, name: 'Grind hub', image: '/images/home/collabs/grind_hub.png' },
  {
    id: 18,
    name: 'Hexagon labs',
    image: '/images/home/collabs/hexagon_labs.png',
  },
  { id: 19, name: 'Intoverse', image: '/images/home/collabs/intoverse.png' },
  {
    id: 20,
    name: 'Jerry alpha',
    image: '/images/home/collabs/jerry_alpha.png',
  },
  { id: 21, name: 'Life’s Meta', image: '/images/home/collabs/lifes_meta.png' },
  {
    id: 22,
    name: 'Magic store',
    image: '/images/home/collabs/magic_store.png',
  },
  { id: 23, name: 'Metamerge', image: '/images/home/collabs/metamerge.png' },
  {
    id: 24,
    name: 'Miningverse',
    image: '/images/home/collabs/miningverse.png',
  },
  { id: 25, name: 'Nft bridge', image: '/images/home/collabs/nft_bridge.png' },
  { id: 26, name: 'Nft hub', image: '/images/home/collabs/nft_hub.png' },
  {
    id: 27,
    name: 'Nunu spirits',
    image: '/images/home/collabs/nunu_spirits.png',
  },
  { id: 28, name: 'Obvious', image: '/images/home/collabs/obvious.png' },
  {
    id: 29,
    name: 'Ordinal eggs',
    image: '/images/home/collabs/ordinal_eggs.png',
  },
  {
    id: 30,
    name: 'Raiders lab',
    image: '/images/home/collabs/raiders_lab.png',
  },
  {
    id: 31,
    name: 'Sharp trade',
    image: '/images/home/collabs/sharp_trade.png',
  },
  { id: 32, name: 'Skale', image: '/images/home/collabs/skale.png' },
  { id: 33, name: 'Syndika', image: '/images/home/collabs/syndika.png' },
  { id: 34, name: 'Tangerine', image: '/images/home/collabs/tangerin.png' },
  {
    id: 35,
    name: 'Ton capital',
    image: '/images/home/collabs/ton_capital.png',
  },
  {
    id: 36,
    name: 'Top tier signal',
    image: '/images/home/collabs/top_tier_signal.png',
  },
  {
    id: 37,
    name: 'Tropa da rih',
    image: '/images/home/collabs/tropa_da_rih.png',
  },
  { id: 38, name: 'Ultiverse', image: '/images/home/collabs/ultiverse.png' },
  { id: 39, name: 'Versa web3', image: '/images/home/collabs/versa_web3.png' },
  { id: 40, name: 'Word4zz', image: '/images/home/collabs/word4zz.png' },
  { id: 41, name: 'Xcelerator', image: '/images/home/collabs/xcelerator.png' },
  { id: 42, name: 'Xdao', image: '/images/home/collabs/xdao.png' },
  { id: 43, name: 'Xhashtag', image: '/images/home/collabs/xhashtag.png' },
  { id: 44, name: 'Zkgames', image: '/images/home/collabs/zkgames.png' },
  { id: 45, name: 'Zooverse', image: '/images/home/collabs/zooverse.png' },
]

onMounted(() => {
  if (document.querySelector('script[data-spline]')) return

  const script = document.createElement('script')
  script.type = 'module'
  script.src = 'https://unpkg.com/@splinetool/viewer/build/spline-viewer.js'
  script.setAttribute('data-spline', 'true')
  script.onerror = () => console.error('Failed to load SplineViewer script')

  document.body.appendChild(script)
})
</script>

<template>
  <div>
    <div class="flex flex-col gap-3.5 text-center relative">
      <div class="h-166 relative overflow-hidden z-2 lg:h-175">
        <NuxtImg
          fetchpriority="high"
          format="webp"
          src="/images/home/gradient.png"
          alt=""
          width="1343"
          height="1045"
          class="object-cover h-full absolute z-1"
        />
        <ClientOnly>
          <div
            class="absolute left-0 w-500 transform -translate-x-1/2 h-full z-2 lg:translate-0 lg:w-auto lg:right-0"
          >
            <spline-viewer
              url="https://prod.spline.design/k-xYdIMLBOGBJGqh/scene.splinecode"
            />
          </div>
        </ClientOnly>
        <div
          class="h-25.5 w-full bg-gradient-to-t from-[#151515] to-transparent absolute bottom-0 z-4"
        />
      </div>

      <div
        class="absolute px-5 flex flex-col gap-3.5 z-5 <lg:left-0 <lg:right-0 <lg:bottom-0 lg:top-87.5 lg:left-34.5 lg:bottom-none lg:right lg:text-left"
      >
        <h1 class="text-12 font-800 leading-12 uppercase lg:text-13.5">
          {{ t('real-world-on-chain') }}
        </h1>

        <h2 class="text-5 tracking-wide uppercase leading-6 lg:max-w-175">
          {{
            t(
              'tokenized-and-ai-powered-events-properties-and-engagement-campaigns'
            )
          }}
        </h2>

        <div class="flex flex-row gap-5 justify-center lg:justify-start lg:mt-8">
          <AppStore>
            <template #custom>
              <div
                class="p-0.25 rounded-full overflow-hidden"
                style="
                  background: linear-gradient(
                    85.11deg,
                    #7540ee -2.65%,
                    #4467ff 47.09%,
                    #ff8fe9 93.65%
                  );
                "
              >
                <div
                  class="bg-[#221c2a] rounded-full flex flex-row items-center gap-2 py-2.5 px-3.5"
                >
                  <i class="i-belong:appstore w-5 h-5" />
                  <p class="uppercase font-500 text-sm tracking-wide">
                    {{ t('app-store-0') }}
                  </p>
                </div>
              </div>
            </template>
          </AppStore>
          <GooglePlay>
            <template #custom>
              <div
                class="p-0.25 rounded-full overflow-hidden"
                style="
                  background: linear-gradient(
                    85.11deg,
                    #7540ee -2.65%,
                    #4467ff 47.09%,
                    #ff8fe9 93.65%
                  );
                "
              >
                <div
                  class="bg-[#221c2a] rounded-full flex flex-row items-center gap-2 py-2.5 px-3.5"
                >
                  <i class="i-belong:google-play w-5 h-5" />
                  <p class="uppercase font-500 text-sm tracking-wide">
                    {{ t('google-play-0') }}
                  </p>
                </div>
              </div>
            </template>
          </GooglePlay>
        </div>
        <!-- <button
          class="p-0.25 rounded-full w-full max-w-97 mx-auto lg:hidden"
          style="
            background-image: linear-gradient(
              85.11deg,
              #7540ee -2.65%,
              #4467ff 47.09%,
              #ff8fe9 93.65%
            );
          "
        >
          <div class="bg-black rounded-full font-500 text-base py-2.5">
            Get Started
          </div>
        </button> -->
      </div>
    </div>

    <div class="max-w-360 mx-auto relative">
      <p
        class="text-[#7540EE] text-base tracking-wider text-center pb-7.5 uppercase pt-11"
      >
        {{ t('partners-and-grants') }}
      </p>

      <div
        class="w-20 h-37.5 absolute left-0 top-0 m-auto bg-gradient-to-r z-9 from-[#151515] to-transparent"
      />

      <div
        class="w-20 h-37.5 absolute right-0 top-0 m-auto bg-gradient-to-r z-9 to-[#151515] from-transparent"
      />
      <div ref="container" class="keen-slider">
        <div
          v-for="item in partners"
          :key="item.id"
          class="keen-slider__slide h-7.5 flex justify-center items-center"
          :class="partnerClasses[item.id]"
        >
          <i
            :class="item.img"
            class="object-contain w-full h-full opacity-40"
          />
        </div>
      </div>
    </div>

    <div class="pt-10 lg:pt-19">
      <ClientOnly>
        <div
          v-if="largerThanXl"
          class="flex flex-row flex-wrap gap-3 max-w-268 mx-auto"
        >
          <div
            v-for="item in features"
            :key="item.id"
            class="flex"
            :class="{
              'w-145 h-81': item.id === 1,
              'w-120 ': item.id === 2,
              'w-91 h-75': item.id === 3,
              'w-85.5 ': item.id === 4 || item.id === 5,
            }"
          >
            <div
              class="p-0.2 rounded-5 relative w-full"
              style="
                background: linear-gradient(
                  104.04deg,
                  rgba(255, 255, 255, 0.2) 22.71%,
                  rgba(255, 255, 255, 0.5) 41.98%,
                  rgba(255, 255, 255, 0.2) 55.52%
                );
              "
            >
              <p
                class="font-500 text-5 tracking-wide uppercase left-6 top-6 right-10.5 absolute text-left leading-6"
                :class="{ 'max-w-75': item.id === 1 }"
              >
                {{ item.title }}
              </p>
              <NuxtImg
                loading="lazy"
                format="webp"
                class="w-full h-full object-cover rounded-5"
                alt=""
                :src="item.img"
              />
            </div>
          </div>
        </div>
        <swiper-container
          v-if="smallerThanXl"
          :space-between="12"
          :slides-offset-before="20"
          :slides-offset-after="20"
          :virtual="true"
          :pagination="{ clickable: true }"
          :breakpoints="{
            '@0.00': {
              slidesPerView: 1,
            },
            '@0.5': {
              slidesPerView: 1.5,
            },
            '@0.75': {
              slidesPerView: 2,
            },
            '@1.00': {
              slidesPerView: 2.5,
            },
            '@1.50': {
              slidesPerView: 3,
            },
          }"
        >
          <swiper-slide
            v-for="(item, index) in features"
            :key="index"
            :virtualIndex="index"
          >
            <div
              class="p-0.2 rounded-5"
              style="
                background: linear-gradient(
                  104.04deg,
                  rgba(255, 255, 255, 0.2) 22.71%,
                  rgba(255, 255, 255, 0.5) 41.98%,
                  rgba(255, 255, 255, 0.2) 55.52%
                );
              "
            >
              <div class="relative h-117.5">
                <p
                  class="font-500 text-5 tracking-wide uppercase left-6 top-6 right-10.5 absolute text-left"
                >
                  {{ item.title }}
                </p>
                <NuxtImg
                  loading="lazy"
                  format="webp"
                  class="w-full h-117.5 object-cover rounded-5"
                  :class="{
                    'object-[84%]': item.id === 2,
                    'object-left': item.id === 4,
                  }"
                  alt=""
                  :src="item.img"
                />
              </div>
            </div>
          </swiper-slide>
        </swiper-container>
      </ClientOnly>
    </div>

    <div class="flex flex-col gap-12.5 pt-7.5 px-2.5 lg:gap-29.5 lg:pt-35.5">
      <div
        class="font-600 text-8 uppercase leading-9 text-center lg:text-11 lg:max-w-250 lg:mx-auto lg:leading-13 lg:tracking-wide"
        style="
          background: linear-gradient(
              107.92deg,
              #4d61fd 37.78%,
              #bb2bbf 106.06%
            )
            text;
          -webkit-text-fill-color: transparent;
        "
      >
        {{ t('belong-transforms-any') }}
        <!-- <div
          class="min-w-14 h-full min-h-14 rounded-full max-h-14 bg-gradient-to-t to-[#FF92FF] from-[#8980FF]"
        /> -->
        {{
          t(
            'access-point-whether-physical-or-digital-into-programmable-tokens-empowering'
          )
        }}
      </div>

      <NuxtImg
        loading="lazy"
        format="webp"
        class="w-full max-w-290 h-127 object-contain mx-auto hidden lg:flex"
        src="/images/home/belong_access_tokens.png"
        alt=""
      />

      <NuxtImg
        loading="lazy"
        format="webp"
        class="max-w-110 w-full mx-auto lg:hidden"
        src="/images/home/belong_access_tokens_mobile.png"
        alt=""
      />
    </div>

    <div class="pt-7.5 px-5">
      <div
        class="flex flex-col gap-3 text-center lg:flex-row lg:items-end lg:justify-center lg:gap-33.5 lg:pt-22"
      >
        <div class="flex flex-col gap-16.5">
          <div class="flex flex-col lg:items-start lg:gap-3">
            <p class="font-500 text-5 tracking-wide uppercase">
              {{ t('use-cases') }}
            </p>
            <p
              class="font-600 text-6 tracking-wide leading-6.5 lg:text-8 lg:leading-normal"
              style="
                background: linear-gradient(
                    90.03deg,
                    #4d61fd -5.37%,
                    #8247df 48.86%,
                    #bb2bbf 104.19%
                  )
                  text;
                -webkit-text-fill-color: transparent;
              "
            >
              {{ t('one-key-to-access-everything') }}
            </p>
          </div>

          <div class="flex flex-col gap-5">
            <NuxtImg
              loading="lazy"
              format="webp"
              class="max-w-97.5 w-full mx-auto lg:hidden"
              src="/images/home/use_cases_key.png"
              alt=""
            />

            <div
              class="flex flex-col gap-2 mx-auto lg:flex-row lg:flex-wrap lg:max-w-164.5"
            >
              <div
                v-for="item in useCases"
                :key="item.id"
                class="flex flex-row items-center text-start gap-6 rounded-2 max-w-97.5 pl-4 pr-10 h-16 lg:flex-col lg:justify-between lg:h-52.5 lg:w-full lg:max-w-52.5 lg:items-start lg:py-6 lg:pl-6 lg:pr-8.5"
                style="
                  background: linear-gradient(
                    218.16deg,
                    #19161c -8.71%,
                    #212121 94.29%
                  );
                "
              >
                <i
                  :class="item.image"
                  class="min-w-10 max-w-10 w-10 h-10 lg:min-w-12.5 lg:w-12.5 lg:h-12.5"
                />

                <p
                  class="font-300 text-base leading-5 tracking-wide lg:text-5 lg:leading-5.5"
                >
                  {{ item.title }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <NuxtImg
          loading="lazy"
          format="webp"
          class="max-w-65 w-full mx-auto hidden lg:flex lg:h-130 lg:object-cover lg:mx-0"
          src="/images/home/use_cases_key_desktop.png"
          alt=""
        />
      </div>
    </div>

    <div class="relative py-7.5 px-5">
      <i
        class="i-belong:belong-product max-w-96 w-full h-15 flex mx-auto bg-contain xl:max-w-360 xl:h-75 xl:bg-bottom xl:translate-y-10"
      />

      <div class="flex flex-row relative xl:max-w-288.5 xl:mx-auto">
        <div
          class="flex flex-col gap-3 mt-7.5 <xl:absolute <xl:z-1 <xl:left-0 <xl:right-0 <xl:items-center"
        >
          <div
            v-for="item in product"
            :key="item.id"
            class="flex flex-row items-center max-w-94 px-3.5 gap-5 rounded-2 z-9 w-full backdrop-blur-5"
            style="
              background: linear-gradient(
                80.34deg,
                #222222 32.04%,
                rgba(0, 0, 0, 0.1) 89.47%
              );
            "
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
          >
            <i :class="item.image" class="min-w-13 w-13 h-13" />

            <p class="text-base font-300 tracking-wide leading-tight py-5">
              {{ item.title }}
            </p>
          </div>
        </div>

        <NuxtImg
          loading="lazy"
          format="webp"
          class="w-65 h-130.5 -translate-y-3.3 xl:translate-y-0 mx-auto"
          src="/images/home/belong_product.png"
          alt=""
        />

        <div class="flex-col gap-3 mt-7.5 hidden xl:flex xl:static">
          <div
            v-for="item in product1"
            :key="item.id"
            class="flex flex-row items-center max-w-94 px-3.5 gap-5 rounded-2 z-9 w-full backdrop-blur-5"
            style="
              background: linear-gradient(
                218.16deg,
                #19161c -8.71%,
                #212121 94.29%
              );
            "
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
          >
            <i :class="item.image" class="min-w-13 w-13 h-13" />

            <p class="text-base font-300 tracking-wide leading-tight py-5">
              {{ item.title }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="px-3.5 relative max-w-232.5 mx-auto lg:px-20 lg:mt-33.5 lg:max-w-244 xl:px-0"
    >
      <div
        class="flex flex-row justify-between text-[#595959] text-5 tracking-wide uppercase"
      >
        <p class="font-500 mb-5 lg:pl-9.5">{{ t('roadmap') }}</p>
        <p class="font-300 hidden lg:flex">{{ t('2021-2025') }}</p>
      </div>
      <div
        v-for="(yearData, year) in INN_UTIL"
        :key="year"
        class="flex flex-row gap-2.5 lg:gap-52.5"
      >
        <div class="lg:min-w-76.5">
          <div class="sticky top-10">
            <div class="flex flex-row items-center">
              <div class="bg-[#151515] p-0.5">
                <div class="border-0.125 rounded-full border-[#6A3CD4]">
                  <div class="border-4 border-[#151515] rounded-full">
                    <div
                      class="w-1.5 h-1.5 bg-[#FF9BF5] rounded-full"
                      style="box-shadow: 0px 0px 4px 0px #ff9bf580"
                    />
                  </div>
                </div>
              </div>
              <div
                class="flex flex-row items-center justify-between w-full lg:pl-4 lg:flex-col lg:items-start"
              >
                <p
                  class="text-5.5 leading-6.5 lg:text-11 lg:leading-normal font-700 tracking-wide"
                  style="
                    background: linear-gradient(
                        107.92deg,
                        #4d61fd 37.78%,
                        #bb2bbf 106.06%
                      )
                      text;
                    -webkit-text-fill-color: transparent;
                  "
                >
                  {{ yearData.year }}
                </p>
                <p
                  class="hidden lg:flex lg:font-300 lg:uppercase lg:tracking-0.3 lg:text-5 lg:leading-6.5"
                >
                  {{ yearData.title }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-8 pb-10 w-full lg:w-115">
          <p
            class="text-base font-300 text-right leading-7 uppercase tracking-0.3 lg:hidden"
          >
            {{ yearData.title }}
          </p>
          <div
            v-for="(items, quarter) in yearData.quarters"
            :key="quarter"
            class="flex flex-col items-start gap-3.5 lg:gap-10"
          >
            <p
              v-if="String(quarter) !== '0'"
              class="bg-[#212121] rounded-0.5 text-[#D9D9D9] font-500 text-5.5 h-8 text-center"
              :style="{
                width:
                  quarter === 'Q1Q2' || quarter === 'Q3Q4' ? '106px' : '68px',
              }"
            >
              {{
                quarter === 'Q1Q2'
                  ? 'Q1-Q2'
                  : quarter === 'Q3Q4'
                    ? 'Q3-Q4'
                    : quarter
              }}
            </p>
            <div class="flex flex-col gap-3.5">
              <div
                v-for="item in items"
                :key="item.id"
                class="flex flex-row items-center gap-2"
              >
                <i
                  v-if="item.checked"
                  class="i-belong:checkbox min-w-5.5 w-5.5 h-5.25"
                />
                <div
                  v-else
                  class="bg-[#212121] min-w-5 w-5 h-5 rounded-0.75 mr-0.5"
                />

                <p class="text-[#C5C5C5] font-300 text-base tracking-0.3">
                  {{ item.text }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-0.5 h-full absolute top-6 left-5.5 -z-1 lg:left-22 lg:top-0 xl:left-2"
        style="
          background: linear-gradient(
            180deg,
            rgba(117, 65, 237, 0) 0%,
            #7541ed 7.87%,
            rgba(117, 65, 237, 0.98) 93.27%,
            rgba(117, 65, 237, 0) 100%
          );
        "
      >
        <div class="absolute top-0 bg-[#6827FF] w-0.5 transition-all"></div>
      </div>
    </div>
    <div class="pt-7.5 lg:pt-45">
      <div class="max-w-307.5 mx-auto">
        <p class="font-500 text-5 tracking-wide uppercase px-5 mb-4.5 lg:pl-11">
          {{ t('case-study') }}
        </p>
        <ClientOnly>
          <swiper-container
            :space-between="12"
            :virtual="true"
            :pagination="{ clickable: true }"
            :breakpoints="{
              '@0.00': {
                slidesPerView: 1,
                slidesOffsetAfter: 20,
                slidesOffsetBefore: 20,
              },
              '@0.5': {
                slidesPerView: 1.5,
                slidesOffsetAfter: 20,
                slidesOffsetBefore: 20,
              },
              '@0.75': {
                slidesPerView: 2,
                slidesOffsetAfter: 20,
                slidesOffsetBefore: 20,
              },
              '@1.00': {
                slidesPerView: 2.8,
                slidesOffsetAfter: 20,
                slidesOffsetBefore: 20,
              },
              '1280': {
                slidesPerView: 3,
                slidesOffsetAfter: 0,
                slidesOffsetBefore: 0,
              },
            }"
          >
            <swiper-slide
              v-for="(item, index) in caseStudy"
              :key="index"
              :virtualIndex="index"
            >
              <div
                class="hover-container p-0.25 rounded-5 min-h-122.5 h-125"
                :class="{
                  'background-one': index === 0 || index === 1,
                  'background-three': index === 2,
                }"
              >
                <div class="flex flex-col bg-[#0D0D0D] rounded-5 h-full">
                  <NuxtImg
                    loading="lazy"
                    format="webp"
                    :src="`/images/home/gradient_case.png`"
                    class="hover-content <xl:hidden xl:opacity-0 transition-opacity absolute rounded-5 top-0 h-125 w-[calc(100%_-_1px)]"
                    alt=""
                  />

                  <div class="relative mx-4 pt-4">
                    <NuxtImg
                      loading="lazy"
                      format="webp"
                      class="rounded-4 w-full object-cover h-46"
                      :src="item.img"
                      alt=""
                    />

                    <div
                      class="absolute bottom-2 left-2 bg-black rounded-full flex flex-row items-center gap-2 pl-1.5 pr-3"
                    >
                      <i class="i-belong:icon-location-violet w-3 h-3" />
                      <p class="text-base font-300">
                        {{ item.location }}
                      </p>
                    </div>
                  </div>

                  <div
                    class="flex flex-col justify-between mt-3 items-start px-6 pb-5.5 h-full"
                  >
                    <div class="flex flex-col gap-2">
                      <p class="font-600 text-5.5 uppercase">
                        {{ item.title }}
                      </p>

                      <div class="flex flex-col gap-3">
                        <div
                          v-for="(feature, i) in item.features"
                          :key="i"
                          class="flex flex-row items-start gap-1"
                        >
                          <i
                            class="i-belong:checkbox-custom min-w-4.5 w-4.5 h-4.5 mt-0.75"
                          />
                          <div
                            class="text-[#C5C5C5] text-base font-300 leading-4.75"
                          >
                            {{ feature.text }}

                            <p v-if="feature.highlight" class="italic block">
                              {{ feature.highlight }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <NuxtLink
                      :to="localeRoute(item.link)"
                      class="font-600 text-xs tracking-wide py-2 px-5.5 rounded-full hover-content xl:opacity-0 transition-opacity"
                      style="
                        background: linear-gradient(
                          90deg,
                          #7540ee 0%,
                          #af55ff 100%
                        );
                      "
                    >
                      {{ t('learn-more') }}
                    </NuxtLink>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper-container>
        </ClientOnly>
      </div>
    </div>

    <div>
      <p class="font-500 text-5 tracking-wide text-center uppercase">
        {{ t('collabs-and-community') }}
      </p>
    </div>

    <div class="mx-auto overflow-hidden pt-10 pb-18">
      <div class="flex flex-row gap-9 transform -translate-x-10">
        <div
          v-for="i in 28"
          class="min-w-15 min-h-15 max-w-15 w-full max-h-15 h-full bg-gradient-to-b from-[#272727] to-[#151515] rounded-full"
        />
      </div>

      <div class="flex flex-col gap-7.5 pt-10 relative">
        <div
          class="w-30 h-67.5 absolute right-0 top-0 m-auto bg-gradient-to-r z-9 to-[#151515] from-transparent"
        />

        <div
          class="w-30 h-67.5 absolute left-0 top-0 m-auto bg-gradient-to-r z-9 from-[#151515] to-transparent"
        />
        <div ref="collabsFirstHalf" class="keen-slider">
          <div
            v-for="item in collabsAndCommunity.slice(0, 22)"
            :key="item.id"
            class="keen-slider__slide flex justify-center items-center min-w-25 w-full h-25"
          >
            <div class="flex flex-col items-center gap-1.5">
              <NuxtImg
                loading="lazy"
                :src="item.image"
                class="object-contain w-15 h-15"
                :alt="item.name"
              />
              <p
                class="text-sm tracking-wide text-[#969696] text-center leading-4.25 break-words"
              >
                {{ item.name }}
              </p>
            </div>
          </div>
        </div>

        <div ref="collabsSecondHalf" class="keen-slider">
          <div
            v-for="item in collabsAndCommunity.slice(23, 46)"
            :key="item.id"
            class="keen-slider__slide flex justify-center items-center min-w-25 w-full h-25"
          >
            <div class="flex flex-col items-center gap-1.5">
              <NuxtImg
                loading="lazy"
                :src="item.image"
                class="object-contain w-15 h-15"
                :alt="item.name"
              />
              <p
                class="text-sm tracking-wide text-[#969696] text-center leading-4.25"
              >
                {{ item.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row gap-9 transform -translate-x-10 pt-3.5">
        <div
          v-for="i in 28"
          class="min-w-15 min-h-15 max-w-15 w-full max-h-15 h-full bg-gradient-to-b from-[#272727] to-[#151515] rounded-full"
        />
      </div>
    </div>

    <div
      class="max-w-255.5 min-h-61.5 rounded-3.5 overflow-hidden relative mx-5 lg:mx-auto"
    >
      <NuxtImg
        loading="lazy"
        format="webp"
        src="/images/home/event_ticketing.png"
        alt=""
        class="w-full h-full absolute -z-1 left-0 right-0"
      />

      <div class="flex flex-col gap-16.5 px-15 pt-10 pb-14">
        <div
          class="flex flex-row flex-wrap justify-center text-center items-center text-5 lg:text-7 gap-3.5"
        >
          {{ t('event-ticketing') }}

          <i class="i-belong:arrow-ticketing w-16 h-4" />

          {{ t('community-engagement-and-monetization') }}
        </div>

        <div
          class="flex flex-wrap items-center gap-5 justify-center lg:justify-center"
        >
          <AppStore>
            <template #custom>
              <div
                class="p-0.25 rounded-full overflow-hidden"
                style="
                  background: linear-gradient(
                    85.11deg,
                    #7540ee -2.65%,
                    #4467ff 47.09%,
                    #ff8fe9 93.65%
                  );
                "
              >
                <div
                  class="bg-[#000000] rounded-full flex flex-row items-center gap-2 py-2.5 px-3.5"
                >
                  <i class="i-belong:appstore w-5 h-5" />
                  <p class="uppercase font-500 text-sm tracking-wide">
                    {{ t('app-store-1') }}
                  </p>
                </div>
              </div>
            </template>
          </AppStore>
          <GooglePlay>
            <template #custom>
              <div
                class="p-0.25 rounded-full overflow-hidden"
                style="
                  background: linear-gradient(
                    85.11deg,
                    #7540ee -2.65%,
                    #4467ff 47.09%,
                    #ff8fe9 93.65%
                  );
                "
              >
                <div
                  class="bg-[#000000] rounded-full flex flex-row items-center gap-2 py-2.5 px-3.5"
                >
                  <i class="i-belong:google-play w-5 h-5" />
                  <p class="uppercase font-500 text-sm tracking-wide">
                    {{ t('google-play-1') }}
                  </p>
                </div>
              </div>
            </template>
          </GooglePlay>

          <NuxtLink :to="appLink" target="_blank" :aria-label="t('web-app')">
            <div
              class="p-0.25 rounded-full overflow-hidden"
              style="
                background: linear-gradient(
                  85.11deg,
                  #7540ee -2.65%,
                  #4467ff 47.09%,
                  #ff8fe9 93.65%
                );
              "
            >
              <div
                class="bg-[#000000] rounded-full flex flex-row items-center gap-2 py-2.5 px-3.5"
              >
                <i class="i-belong:web_app w-5 h-5" />
                <p class="uppercase font-500 text-sm tracking-wide">
                  {{ t('web-app-0') }}
                </p>
              </div>
            </div>
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.swiper-pagination-bullets {
  @apply !bottom-0;
}

.swiper-pagination-bullet {
  @apply bg-[#212121] w-4 h-4;
}

.swiper-pagination-bullet-active {
  @apply bg-[#6827FF] border-4 border-[#212121];
}

.background-one {
  background: linear-gradient(
    142.08deg,
    rgba(255, 255, 255, 0.28) -5.03%,
    rgba(224, 224, 224, 0.34) 21.3%,
    rgba(101, 84, 169, 0.08) 49.04%,
    rgba(218, 218, 218, 0.2) 70.19%
  );
}

.background-three {
  background: linear-gradient(
    121.74deg,
    rgba(176, 111, 255, 0.4) 26.04%,
    rgba(224, 224, 224, 0.34) 35.88%,
    rgba(101, 84, 169, 0.08) 64%,
    rgba(203, 153, 255, 0.4) 85.45%
  );
}

.hover-container {
  &:hover {
    .hover-content {
      --at-apply: opacity-100;
    }
  }
}

swiper-container {
  &::part(bullet-active) {
    --at-apply: bg-[#6827fffa] border-4 border-solid border-[#1d1d1d];
  }
  &::part(bullet) {
    --at-apply: bg-[#3c3c3c] border-4 border-solid border-[#3c3c3c];
  }
  &::part(container) {
    --at-apply: pb-8.5;
  }
  &::part(pagination) {
    --at-apply: bottom-0;
  }
}
</style>
